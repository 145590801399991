<template>
<div class="vx-row">
    <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <vx-card title="Filter">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(GenerateReport)">
                    <div class="filter-container">
                        <h6 class="font-bold mb-4">Select Department</h6>
                        <div>
                            <ValidationProvider class="w-full" name="Department" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <vs-select class="sm:w-full lg:w-1/1 w-full" name="title" v-model="req.departmentId" placeholder="Select">
                                    <vs-select-item :key="index" :value="item.id" :text="item.clientDepartment" v-for="(item, index) in departments" />
                                </vs-select>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <vs-divider />
                        <h6 class="font-bold mb-3">Start Date</h6>
                        <div>
                            <ValidationProvider name="StartDate" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker class="sm:w-full lg:w-1/1 w-full" width="150px" v-model="req.StartDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <vs-divider />
                        <h6 class="font-bold mb-4">End Date</h6>
                        <div>
                            <ValidationProvider name="Enddate" :rules="{ required: true }" v-slot="{ errors }" :custom-messages="customMessages">
                                <date-picker class="sm:w-full lg:w-1/1 w-full" v-model="req.EndDate" format="YYYY-MM-DD" valueType="YYYY-MM-DD" type="date" placeholder="Select"></date-picker>
                                <span class="text-danger text-sm">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <vs-divider />
                        <div class="flex justify-center">
                            <vs-button class="w-full" @click="GenerateReport">Apply</vs-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </vx-card>
    </div>
    <div class="vx-col md:w-3/4 sm:w-1/2 w-full">
        <vx-card class="item-details mb-base welcomeCard" title="Request Received">
            <template slot="actions">
                <!-- <vs-button color="danger" @click="exportToPDF" :disabled="isExport">Report</vs-button>
                <vs-button class="ml-2" color="success" @click="exportToExcel" :disabled="isExport">Export</vs-button> -->
            </template>
            <vs-table stripe :data="requestReceivedData">
                <template #thead>
                    <vs-th>File Number</vs-th>
                    <vs-th>Name</vs-th>
                    <vs-th>Department</vs-th>
                    <vs-th>Case Officer</vs-th>
                    <vs-th>Request Received</vs-th>
                    <vs-th>Report Details</vs-th>
                </template>
                <template>
                    <vs-tr :key="i" v-for="(tr, i) in requestReceivedData" :data="tr">
                        <vs-td>{{ tr.fileNumber}}</vs-td>
                        <vs-td>{{ tr.displayName}}
                        </vs-td>
                        <vs-td>
                            {{ tr.clientDepartment }}
                        </vs-td>
                        <vs-td>
                            {{ tr.caseOfficer }}
                        </vs-td>
                        <vs-td>
                            {{ tr.requestReceived  | formatLongDate}}
                        </vs-td>
                        <vs-td>
                            <div class="flex">
                                <div class="mr-5">
                                    <span><b>Level:</b> {{ tr.level}}</span><br>
                                    <span><b>Clearance Type:</b> {{ tr.clearanceType}}</span><br>
                                    <span><b>Pack Received:</b> {{ tr.packReceived | formatLongDate}}</span><br>
                                </div>
                                <div>
                                    <span><b>Completed:</b> {{ tr.completed | formatLongDate}}</span><br>
                                    <span><b>Invoiced:</b> {{ tr.invoiced | formatLongDate}}</span><br>
                                    <span><b>Date Of Disposal:</b> {{ tr.dateOfDisposal | formatLongDate}}</span><br>
                                </div>
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    reportsData
} from "../../store/api/report";
import {
    departmentData
} from "../../store/api/department";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
    customMessages
} from "../../filters/validationmessage";

export default {
    data() {
        return {
            departments: [],
            requestReceivedData: [],
            req: {

            },
            isExport: true,
            customMessages,

        }
    },
    components: {
        DatePicker,
    },
    async created() {
        await this.loadDepartments();
    },
    methods: {
        async loadDepartments() {
            this.departments = [];
            this.departments = await departmentData.getAllDepartment();
        },
        async GenerateReport() {

            const success = await this.$refs.form.validate();
            if (!success) {
                return;
            }

            this.requestReceivedData = [];
            var apiResult = await reportsData.RequestReceived(this.req);
            if (!apiResult.succeeded) {
                this.$vs.notify({
                    title: "Error",
                    text: apiResult.message,
                    color: "danger",
                    position: "top-center",
                });
                return;
            }
            this.requestReceivedData = apiResult.data
            this.isExport = false;
        },
        async exportToExcel() {
            let data = await reportsData.ExportToExcelRequestReceived(this.req);
            //window.open(data.url, '_blank').focus();
        },
        async exportToPDF() {
            let data = await reportsData.ExportToPDFRequestReceived(this.req);
            //window.open(data.url, '_blank').focus();
        },
    }
}
</script>
